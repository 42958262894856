@charset "UTF-8";
@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 3, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 20;
  background-color: #7887c0;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.7);
}

/* line 14, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__logo {
  padding: 0px 50px;
  max-height: 80px;
  justify-self: flex-start;
  cursor: pointer;
}

@media (max-width: 700px) {
  /* line 14, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
  .header__logo {
    padding: 0px 10px;
  }
}

/* line 21, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content {
  display: flex;
  align-items: center;
  margin-right: 50px;
  height: 80px;
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__burger {
  display: none;
}

@media (max-width: 1024px) {
  /* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
  .header__content__burger {
    display: block;
    cursor: pointer;
  }
}

/* line 35, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-start;
}

@media (max-width: 1024px) {
  /* line 35, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
  .header__content__nav {
    display: none;
  }
}

/* line 42, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__nav__a {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  font-size: clamp(9px, calc(0px + 0.9vw), 16px);
  color: #fffcf8;
  padding-top: 31px;
  margin-right: 40px;
  cursor: pointer;
}

/* line 51, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__nav__a:hover {
  border-top: 5px solid #fffcf8;
  text-transform: uppercase;
  font-family: 'main';
  font-weight: 400;
  color: #fffcf8;
  padding-top: 26px;
  text-decoration: none;
  height: 54px;
}

/* line 60, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin-right: 50px;
  margin: 10px;
  transition: all 0.5s ease-out;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.header__content__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

/* line 62, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.header__content__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@media (max-width: 700px) {
  /* line 62, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
  .header__content__button__hover {
    padding: 5px 10px;
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  /* line 62, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
  .header__content__button__hover {
    font-size: 12px;
    min-height: 35px;
  }
}

/* line 71, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__nav__dropdown {
  position: relative;
  display: none;
}

/* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.active .header__content__nav__dropdown-content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: absolute;
  top: 100%;
  right: 50px;
  background-color: #7887c0;
  padding: 10px;
  z-index: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.7);
}

@media (min-width: 1024px) {
  /* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
  .active .header__content__nav__dropdown-content {
    display: none;
  }
}

/* line 91, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.active .header__content__nav__dropdown-content__a {
  padding: 10px 20px;
  text-decoration: none;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fffcf8;
}

/* line 96, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.active .header__content__nav__dropdown-content__a:hover {
  color: #FFC93C;
}

/* line 99, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/header.sass */
.header__content__nav__dropdown-content {
  display: none;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 3, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main {
  min-width: 100%;
  max-width: 100%;
}

/* line 6, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__up {
  position: fixed;
  right: 20px;
  bottom: 100px;
  width: 65px;
  z-index: 100;
  cursor: pointer;
}

/* line 15, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout {
  border-radius: 30px;
  max-width: 90vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 21, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content {
  position: relative;
  width: fit-content;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

@media (max-width: 768px) {
  /* line 21, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content {
    max-width: 90vw;
    margin-top: 50px;
  }
}

/* line 31, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: #243E6C;
}

@media (max-width: 670px) {
  /* line 31, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content__title {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  /* line 31, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content__title {
    font-size: 18px;
    padding: 0px 15px;
  }
}

/* line 41, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__a {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #7887c0;
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
}

/* line 48, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__a:hover {
  text-decoration: underline;
  color: #c18f0d;
  transition: all 0.5s ease-out;
}

/* line 52, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__p {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #243E6C;
  text-decoration: none;
  text-align: start;
  padding: 0px 50px;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 500px) {
  /* line 52, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content__p {
    padding: 0px 10px;
  }
}

/* line 63, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__sales {
  padding: 15px;
}

@media (max-width: 768px) {
  /* line 63, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content__sales {
    padding: 5px;
  }
}

/* line 67, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__sales__note {
  font-family: 'main';
  margin-bottom: 10px;
  font-size: 14px;
  color: #414141b6;
  color: #ff3c00d8;
  font-weight: 400;
  text-decoration: none;
  text-align: end;
}

/* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__sales__sale {
  margin-bottom: 20px;
}

/* line 78, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__sales__sale__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  color: #243E6C;
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  /* line 78, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content__sales__sale__title {
    font-size: 16px;
  }
}

/* line 85, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__choice__modal__layout__content__sales__sale__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #525c85;
  margin-left: 10px;
}

@media (max-width: 768px) {
  /* line 85, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__choice__modal__layout__content__sales__sale__text {
    font-size: 14px;
  }
}

/* line 93, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section {
  background-attachment: fixed;
  background-size: cover;
  min-height: fit-content;
  max-height: fit-content;
  padding-bottom: 0px;
  background-color: #fffcf8;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

/* line 103, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting {
  z-index: 10;
  width: 100vw;
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc( 100vh - 80px);
  max-height: calc( 100vh - 80px);
  flex-direction: column;
  min-height: fit-content;
  max-height: fit-content;
  background-color: #7887c0;
}

/* line 113, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__greetPlane1 {
  width: 20vw;
  position: absolute;
  left: 10px;
  top: 10px;
}

@media (max-width: 460px) {
  /* line 113, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__greetPlane1 {
    opacity: 0.5;
  }
}

/* line 120, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__greetPlane2 {
  width: 20vw;
  position: absolute;
  right: 10px;
  bottom: -100px;
}

/* line 125, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__greet1 {
  opacity: 0.7;
  width: 20vw;
  position: absolute;
  right: 20%;
  top: 10px;
}

/* line 131, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__greet2 {
  width: 25vw;
  opacity: 0.2;
  position: absolute;
  left: 20%;
  bottom: -130px;
}

/* line 137, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 100px 0px 20px 0px;
  min-height: 50vh;
}

/* line 149, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__number {
  position: absolute;
  top: 20px;
  right: 0px;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fffcf8;
  align-self: flex-start;
  margin-bottom: 20px;
  font-size: 24px;
  align-self: flex-end;
  transition: all 0.5s ease-out;
}

/* line 160, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__number:hover {
  transition: all 0.5s ease-out;
  color: #FFC93C;
}

/* line 163, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 50vw;
  z-index: 2;
}

@media (max-width: 650px) {
  /* line 163, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left {
    margin-top: 50px;
  }
}

@media (max-width: 500px) {
  /* line 163, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left {
    margin-top: 100px;
  }
}

/* line 174, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__h1 {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #fffcf8;
  width: 50vw;
  align-self: flex-start;
  margin-bottom: 20px;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 174, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__h1 {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  /* line 174, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__h1 {
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  /* line 174, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__h1 {
    font-size: 40px;
  }
}

@media (max-width: 425px) {
  /* line 174, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__h1 {
    font-size: 30px;
  }
}

/* line 186, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__p {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #fffcf8;
  align-self: flex-start;
  margin-bottom: 20px;
  font-size: 24px;
}

@media (max-width: 768px) {
  /* line 186, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__p {
    font-size: 20px;
  }
}

@media (max-width: 650px) {
  /* line 186, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__p {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  /* line 186, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__p {
    font-size: 16px;
  }
}

/* line 201, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin-right: 50px;
  margin: 10px;
  transition: all 0.5s ease-out;
  align-self: flex-end;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__greeting__content__left__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

/* line 208, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  background-color: #FFC93C;
  font-size: 18px;
  font-weight: 600;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__greeting__content__left__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  /* line 208, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__button__hover {
    font-size: 14px;
  }
}

/* line 219, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__buttons {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0px;
  margin: 0px;
}

/* line 227, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__buttons__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin-right: 50px;
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 220px;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__greeting__content__left__buttons__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

@media (max-width: 700px) {
  /* line 227, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__buttons__button {
    max-width: 175px;
  }
}

/* line 232, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__buttons__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__greeting__content__left__buttons__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@media (max-width: 700px) {
  /* line 232, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__buttons__button__hover {
    padding: 5px;
  }
}

/* line 237, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__buttons__button__hover.selected {
  transition: all 0.5s ease-out;
  background-color: #FFC93C;
}

/* line 240, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__left__buttons__button__hover__link {
  color: #525c85;
  font-family: 'main';
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
}

@media (max-width: 700px) {
  /* line 240, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
  .main__section__greeting__content__left__buttons__button__hover__link {
    font-size: 12px;
  }
}

/* line 248, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__right {
  position: relative;
  width: 100%;
  height: 100%;
}

/* line 252, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.main__section__greeting__content__right__china1 {
  position: absolute;
  top: 0px;
  width: 100px;
}

/* line 262, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.swiper-slide-shadow {
  display: none !important;
}

/* line 265, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.swiper-button-prev {
  content: url(../img/arrow_left.svg);
  left: 0px !important;
}

/* line 269, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.swiper-button-next {
  content: url(../img/arrow_right.svg);
  right: 0px !important;
}

/* line 276, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.swiper-pagination-bullet {
  background-color: rgba(35, 32, 32, 0.3) !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

/* line 279, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.swiper-pagination-bullet-active {
  background-color: #FFCB03 !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

/* line 284, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.hystmodal__close:focus {
  outline: none !important;
}

/* line 288, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.hystmodal__window {
  background-color: #ffffff00 !important;
  max-width: fit-content !important;
}

/* line 297, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/main.sass */
.sliderComments {
  max-width: 70vw;
  min-width: 70vw;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool {
  z-index: 2;
  height: fit-content;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* line 14, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__test {
  position: absolute;
  top: -70px;
  opacity: 0.1;
  width: 100%;
  z-index: 0;
}

/* line 20, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__plane1 {
  opacity: 0.5;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40vw;
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__plane2 {
  opacity: 0.5;
  position: absolute;
  right: 0px;
  bottom: 20%;
  width: 40vw;
}

/* line 32, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content {
  z-index: 1;
  display: flex;
  flex-direction: column;
}

/* line 37, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1 {
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1:nth-child(1) {
  margin-bottom: 30px;
}

/* line 46, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__info {
  width: 50vw;
  margin-left: 30px;
  margin-top: 200px;
  color: #243E6C;
}

/* line 51, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__info__title {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  font-size: 56px;
  margin-bottom: 15px;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 51, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
  .main__section__preschool__content__block1__info__title {
    font-size: 48px;
  }
}

/* line 55, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__info__point {
  display: flex;
  align-items: center;
  margin: 0px 0px 30px 50px;
}

/* line 59, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__info__point__img {
  width: 5px;
  margin-right: 15px;
}

/* line 62, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__info__point__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  white-space: pre-line;
}

/* line 65, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__note {
  align-self: flex-start;
}

/* line 67, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block1__note__noteImg {
  width: 25vw;
}

/* line 69, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block2 {
  height: fit-content;
  width: 90vw;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* line 77, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block2__info {
  color: #243E6C;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* line 82, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block2__info__title {
  align-self: center;
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  font-size: 56px;
  margin-bottom: 15px;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 82, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
  .main__section__preschool__content__block2__info__title {
    font-size: 48px;
  }
}

/* line 87, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block2__note {
  align-self: flex-start;
}

/* line 89, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/preschool.sass */
.main__section__preschool__content__block2__note__noteImg {
  width: 25vw;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm {
  height: fit-content;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7887c0;
}

/* line 13, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__plane1 {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.3;
}

/* line 18, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__plane2 {
  position: absolute;
  right: 0px;
  bottom: -100px;
  opacity: 0.3;
}

/* line 23, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__title {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #fffcf8;
  font-size: 50px;
  z-index: 2;
  text-align: center;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 23, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
  .main__section__programm__title {
    font-size: 48px;
  }
}

/* line 29, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

/* line 35, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider {
  width: 50vw;
  height: fit-content;
}

/* line 39, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider .swiper-button-prev {
  content: url("../img/arrow_left_light.svg");
}

/* line 41, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider .swiper-button-next {
  content: url("../img/arrow_right_light.svg");
}

/* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #fffcf8 !important;
}

/* line 46, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #FFC93C !important;
}

/* line 48, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider__slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* line 52, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider__slide__card {
  margin: 50px 0px;
  width: fit-content;
  height: fit-content;
  background-color: #fffcf8;
  border-radius: 30px;
  padding: 10px;
  border: 2px solid #525c85;
  box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.25);
}

/* line 61, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider__slide__card__dashed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  min-height: 55vh;
  padding: 20px;
  border-radius: 30px;
  border: 2px dashed #525c85;
}

/* line 71, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider__slide__card__dashed__title {
  font-family: 'main';
  font-size: 36px;
  font-weight: 600;
  color: #243E6C;
  margin-bottom: 20px;
}

/* line 75, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider__slide__card__dashed__img {
  width: 10vw;
  margin-bottom: 20px;
}

/* line 78, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/programm.sass */
.main__section__programm__container__slider__slide__card__dashed__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #525c85;
  text-align: center;
  line-height: 175%;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 3, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main {
  min-width: 100%;
  max-width: 100%;
}

/* line 7, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding-top: 50px;
}

/* line 13, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__h1 {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #525c85;
  margin-bottom: 50px;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 13, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
  .main__section__advantages__h1 {
    font-size: 48px;
  }
}

/* line 17, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-width: 90vw;
  max-width: 90vw;
}

/* line 23, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container__slider {
  width: 100%;
}

/* line 25, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container__slider .swiper-button-prev {
  content: url(../img/arrow_left.svg);
  fill: #e01d1d !important;
}

/* line 28, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container__slider .swiper-button-next {
  content: url(../img/arrow_right.svg);
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 40px 0px;
}

/* line 36, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container__slider__slide__img {
  margin-bottom: 20px;
}

/* line 38, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/advantages.sass */
.main__section__advantages__container__slider__slide__name {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  min-width: 350px;
  max-width: 350px;
  font-size: 20px;
  color: #525c85;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
}

/* line 10, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__waves {
  object-fit: cover;
  width: 100%;
  width: 150%;
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container {
  z-index: 2;
  width: -webkit-fill-available;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* line 25, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__h1 {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #7887c0;
  margin-bottom: 30px;
  margin-top: 50px;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 25, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
  .main__section__forWhom__container__h1 {
    font-size: 48px;
  }
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content {
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

/* line 35, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__info {
  padding: 30px;
  border-radius: 30px;
  background-color: #fffcf8;
  box-shadow: 5px 5px 10px 0px rgba(157, 162, 174, 0.9) inset, -5px -5px 10px 0px rgba(255, 255, 255, 0.9) inset, 5px -5px 10px 0px rgba(157, 162, 174, 0.2) inset, -5px 5px 10px 0px rgba(157, 162, 174, 0.2) inset, -1px -1px 2px 0px rgba(157, 162, 174, 0.5), 1px 1px 2px 0px rgba(255, 255, 255, 0.3);
  min-width: 30vw;
  max-width: 30vw;
  margin-right: 50px;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  align-self: center;
}

/* line 47, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__info__kids, .main__section__forWhom__container__content__info__adult {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/* line 52, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__info__kids__title, .main__section__forWhom__container__content__info__adult__title {
  align-self: center;
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}

/* line 56, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__info__kids__point, .main__section__forWhom__container__content__info__adult__point {
  font-family: 'main';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

/* line 61, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__info__kids__text, .main__section__forWhom__container__content__info__adult__text {
  font-family: 'main';
  font-size: 16px;
  line-height: 175%;
  font-weight: 400;
  margin-bottom: 30px;
}

/* line 67, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40vw;
  max-width: 40vw;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  color: #7887c0;
  margin-bottom: 80px;
  max-width: 470px;
  text-align: center;
}

/* line 80, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box__choice {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
}

@media (max-width: 1024px) {
  /* line 80, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
  .main__section__forWhom__container__content__box__choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

/* line 91, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box__choice__age {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

/* line 96, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box__choice__age:nth-child(1) {
  margin-right: 40px;
}

@media (max-width: 1024px) {
  /* line 96, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
  .main__section__forWhom__container__content__box__choice__age:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 40px;
  }
}

/* line 101, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box__choice__age__img {
  width: 20vw;
  margin-bottom: 30px;
}

/* line 104, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/forWhom.sass */
.main__section__forWhom__container__content__box__choice__age__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  color: #fffcf8;
  background-color: #7887c0;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__forWhom__container__content__box__choice__age__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about {
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  background-color: #7887c0;
}

/* line 14, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 300px;
}

/* line 21, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin-right: 50px;
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 250px;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__about__container__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

/* line 24, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__about__container__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1 {
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px;
}

/* line 33, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__left__title {
  padding: 30px 100px;
  background: url(../img/smear.svg) no-repeat;
  background-size: contain;
  position: relative;
  margin-bottom: 65px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

/* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__left__title__h1 {
  text-align: center;
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #7887c0;
  font-size: clamp(48px, calc(48px + 1vw), 24px);
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
  .main__section__about__container__group1__left__title__h1 {
    font-size: 48px;
  }
}

/* line 50, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__left__points {
  margin-left: 100px;
  justify-self: right;
}

/* line 53, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__left__points__point {
  display: flex;
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #fffcf8;
  margin-bottom: 30px;
}

/* line 59, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__left__points__point__img {
  margin-right: 30px;
}

/* line 61, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__right {
  align-self: center;
  width: fit-content;
}

/* line 64, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group1__right__img {
  width: 30vw;
}

/* line 66, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2 {
  width: 80vw;
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

/* line 72, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__right {
  display: block;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__right__title {
  padding: 40px 110px 10px;
  background: url(../img/smear2.svg) no-repeat center;
  background-size: contain;
  position: relative;
  margin-bottom: 65px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

/* line 85, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__right__title__h1 {
  text-align: center;
  z-index: 2;
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #7887c0;
  line-height: 105%;
  font-size: clamp(48px, calc(48px + 1vw), 24px);
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 85, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
  .main__section__about__container__group2__right__title__h1 {
    font-size: 48px;
  }
}

/* line 93, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__right__points {
  margin-left: 100px;
  justify-self: right;
}

/* line 96, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__right__points__point {
  display: flex;
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #fffcf8;
  margin-bottom: 80px;
}

/* line 102, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__right__points__point__img {
  margin-right: 30px;
}

/* line 104, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__left {
  align-self: flex-end;
  width: fit-content;
}

/* line 107, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__container__group2__left__img {
  width: 25vw;
}

/* line 109, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/about.sass */
.main__section__about__waves {
  object-fit: cover;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results {
  height: fit-content;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7887c0;
}

/* line 13, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container {
  width: 90vw;
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  font-weight: 600;
  max-width: 90vw;
  color: #fffcf8;
  margin-bottom: 40px;
  align-self: flex-start;
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin-right: 50px;
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 250px;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__results__container__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

/* line 29, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  color: #7887c0;
  background-color: #fffcf8;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__results__container__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

/* line 33, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__content {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

/* line 38, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__content__points {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 40px;
}

/* line 45, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__content__points__point {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

/* line 49, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__content__points__point__img {
  width: 20px;
  margin-right: 15px;
}

/* line 52, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__content__points__point__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #fffcf8;
}

/* line 55, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/results.sass */
.main__section__results__container__content__img {
  align-self: flex-end;
  width: 30vw;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 3, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main {
  min-width: 100%;
  max-width: 100%;
}

/* line 7, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

/* line 13, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__gradient {
  object-fit: cover;
  width: 100%;
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container {
  width: 95vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

/* line 23, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin-right: 50px;
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 250px;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__rates__container__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: #7887c0;
  color: #fffcf8;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__rates__container__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__title {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #525c85;
  text-align: center;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
  .main__section__rates__container__title {
    font-size: 48px;
  }
}

/* line 34, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages {
  width: 95vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* line 39, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
}

/* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* line 49, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__duration {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* line 53, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__duration__time {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  transition: all 0.5s ease-out;
  border: none;
  background-color: #ffffff00;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 15px;
}

/* line 65, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__duration__time:hover {
  transition: all 0.5s ease-out;
  background-color: #FFC93C;
  border-radius: 30px;
}

/* line 69, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__duration__time:nth-child(1) {
  margin-right: 15px;
}

/* line 71, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__duration__time.selected {
  padding: 5px 15px;
  background-color: #FFC93C;
  border-radius: 30px;
  font-weight: 500;
}

/* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__title {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: 50px;
  color: #525c85;
  font-size: 24px;
  padding: 10px 15px;
  border-radius: 30px;
  align-self: flex-end;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0) 0%, rgba(255, 201, 60, 0.28) 100%);
}

/* line 86, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package .groupTitle {
  align-self: flex-start;
  margin-bottom: 50px;
  margin-left: 50px;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.28) 0%, rgba(255, 201, 60, 0) 100%);
}

/* line 91, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider {
  width: 40vw;
  box-shadow: none;
}

/* line 94, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider .swiper-pagination {
  bottom: 30px !important;
}

/* line 96, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider .swiper-button-prev {
  content: url("../img/arrow-left-rates.svg");
  top: auto !important;
  bottom: 20px !important;
  left: 35% !important;
  z-index: 11;
}

/* line 102, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider .swiper-button-next {
  content: url("../img/arrow-right-rates.svg");
  top: auto !important;
  bottom: 20px !important;
  right: 35% !important;
  z-index: 11;
}

/* line 108, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide {
  padding: 15px 0px 40px 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

/* line 114, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide.swiper-slide-shadow {
  display: none !important;
}

/* line 116, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  min-height: 600px;
  max-height: 600px;
  padding: 50px 40px 40px 30px;
  border-radius: 40px;
  border: 1px solid #272E48;
  background: #E0E7F8;
  box-shadow: none;
  color: #243E6C;
}

@media (max-width: 600px) {
  /* line 116, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card {
    padding: 50px 30px 30px 30px;
    min-height: 630px;
    max-height: 630px;
  }
}

@media (max-width: 430px) {
  /* line 116, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card {
    padding: 40px 10px 10px 10px;
    min-height: 670px;
    max-height: 670px;
  }
}

/* line 139, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__sale {
  position: absolute;
  top: -6px;
  left: -7px;
}

/* line 143, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__infoSale {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

/* line 150, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__infoSale:hover {
  text-decoration: underline;
  color: #FFC93C;
  transition: all 0.5s ease-out;
}

/* line 154, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__h3 {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
}

/* line 157, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__title {
  font-family: 'main';
  font-size: 36px;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 5px;
}

/* line 161, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__format {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
}

/* line 165, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__img {
  width: 150px;
  margin-bottom: 20px;
}

/* line 168, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__features {
  max-width: 350px;
  margin-bottom: 10px;
}

/* line 171, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__features__point {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

/* line 176, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__oldPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'main';
  font-size: 36px;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  margin-bottom: 10px;
}

/* line 185, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__oldPrice::after {
  position: absolute;
  content: ' ';
  top: 40%;
  width: 100%;
  height: 2px;
  background-color: #8b0000;
  transform: rotate(-5deg) translateY(-50%);
  left: auto;
}

/* line 194, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'main';
  font-size: 36px;
  font-weight: 600;
  font-size: 40px;
  position: relative;
  margin-bottom: 20px;
}

/* line 204, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__price__note {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  color: #243E6C;
  position: relative;
}

/* line 211, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__price__note__oldPrice {
  position: relative;
}

/* line 213, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__price__note__oldPrice::after {
  position: absolute;
  content: ' ';
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: #8b0000;
  transform: rotate(-15deg) translateY(-50%);
  left: 0px;
}

/* line 222, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/rates.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  color: #fffcf8;
  background-color: #525c85;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__rates__container__packages__age__package__slider__slide__card__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7887c0;
  height: fit-content;
  width: 100%;
}

/* line 14, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__waves {
  object-fit: cover;
  width: 100%;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container {
  max-height: 50vh;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: calc( 100vw - 80px);
  max-width: calc( 100vw - 80px);
}

/* line 29, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__title {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  color: #fffcf8;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 29, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
  .main__section__feedback__container__title {
    font-size: 48px;
  }
}

/* line 33, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content {
  min-width: fit-content;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 39, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider {
  min-width: 80vw;
  max-width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 45, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider .swiper-button-prev {
  content: url("../img/arrow_left_light.svg");
}

/* line 47, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider .swiper-button-next {
  content: url("../img/arrow_right_light.svg");
}

/* line 50, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #fffcf8 !important;
}

/* line 52, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #FFC93C !important;
}

/* line 54, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: clip;
  margin-bottom: 20px;
}

/* line 60, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30vw;
  height: 20vh;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 30px;
  margin-top: 25px;
}

/* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__points1 {
  position: absolute;
  top: -20px;
  left: 60px;
}

/* line 80, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__points2 {
  position: absolute;
  bottom: -20px;
  right: 30px;
}

/* line 84, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

/* line 89, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__avatar__img {
  align-self: flex-start;
  border-radius: 30px;
  margin: 5px 15px;
  width: 60px;
}

/* line 94, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__avatar__state {
  font-family: 'main';
  font-size: 12px;
  font-weight: 400;
  color: #575757;
}

/* line 99, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__likes {
  font-family: 'main';
  font-size: 12px;
  font-weight: 500;
  color: #7887c0;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 10px;
}

/* line 108, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__likes__img {
  width: 20px;
}

/* line 110, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 5px 0px 10px 0px;
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
}

/* line 118, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__info__name {
  font-family: 'main';
  color: #243E6C;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.5s ease-out;
}

/* line 125, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__info__name:hover {
  text-decoration: underline;
  color: #c48e19;
  transition: all 0.5s ease-out;
}

/* line 131, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__info__date {
  font-family: 'main';
  margin-bottom: 10px;
  font-size: 13px;
  color: #616161b6;
  font-weight: 400;
  text-decoration: none;
}

/* line 138, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__info__date:hover {
  text-decoration: underline;
  color: #c48e19;
  transition: all 0.5s ease-out;
}

/* line 142, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/feedback.sass */
.main__section__feedback__container__content__slider__slide__comment__info__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #243E6C;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  max-height: 10em;
  overflow-y: auto;
  padding-right: 10px;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding-top: 50px;
  margin-bottom: 50px;
}

/* line 12, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__title {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  text-align: center;
  margin: 10px 30px;
  color: #243E6C;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 12, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
  .main__section__teachers__title {
    font-size: 48px;
  }
}

/* line 17, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: calc( 100vw - 200px);
  max-width: calc( 100vw - 200px);
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider {
  width: 90vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* line 33, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

/* line 39, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__name {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  color: #243E6C;
  font-size: 18px;
  text-align: center;
  width: 80%;
}

/* line 45, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card {
  position: relative;
  width: 100%;
  width: 70%;
  margin-bottom: 10px;
}

/* line 50, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__img {
  width: 100%;
  border-radius: 30px;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
}

/* line 54, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover {
  opacity: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 6.77%, rgba(0, 0, 0, 0) 100%);
  border-radius: 30px;
  position: absolute;
  bottom: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  transition: all 0.5s ease-out;
}

/* line 56, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
}

/* line 71, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover__container {
  padding: 15px;
  max-height: calc( 100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

/* line 78, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover__container__name {
  color: #fff;
  margin-bottom: 10px;
  font-family: 'main';
  font-size: 16px;
  font-weight: 500;
}

/* line 84, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover__container__info {
  color: #fff;
  margin-bottom: 5px;
  font-family: 'main';
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 60%;
}

/* line 93, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover__container__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: 'main';
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  align-self: flex-end;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__teachers__container__slider__slide__card__hover__container__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

/* line 107, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__container__slider__slide__card__hover__container__button__arrow {
  margin-left: 5px;
}

/* line 109, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp {
  margin: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* line 115, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal {
  position: relative;
  width: 60vw;
  height: 80vh;
  margin: 0px;
}

/* line 120, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content {
  max-height: 80vh !important;
  width: 100% !important;
  margin: 0px !important;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  padding: 45px 45px 30px 30px;
  position: relative;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0) 100%) !important;
}

/* line 131, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__left {
  width: 30%;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* line 138, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__left__img {
  width: 60%;
  border-radius: 200px;
  margin-bottom: 20px;
}

/* line 142, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__left__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* line 146, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__left__info__name {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  color: #243E6C;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
}

/* line 152, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__left__info__post {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  text-align: center;
}

/* line 158, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__right {
  width: 60%;
  max-height: 40rem;
  overflow-y: auto;
  padding-right: 20px;
}

/* line 163, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.main__section__teachers__popUp__modal__content__right__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #243E6C;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  text-indent: 20px;
}

/* line 173, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/teachers.sass */
.window__teacher {
  background-color: #fffcf8 !important;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 5, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding-top: 50px;
  margin-bottom: 50px;
}

/* line 12, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__title {
  font-family: 'display';
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  padding-top: 15px;
  text-align: center;
  color: #243E6C;
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 12, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
  .main__section__diplomas__title {
    font-size: 48px;
  }
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__container {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: calc( 100vw - 200px);
  max-width: calc( 100vw - 200px);
}

/* line 25, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__container__slider {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__container__slider.swiper-wrapper {
  align-items: center;
}

/* line 32, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 40px;
}

/* line 39, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__container__slider__slide__img {
  border: 1px solid #474747;
  position: relative;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 30px;
  border-radius: 30px;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
}

/* line 48, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__popUp {
  margin: 0px auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* line 54, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__popUp__modal {
  position: relative;
  width: 90vw;
  height: 90vh;
  margin: 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* line 62, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__popUp__modal__content {
  width: fit-content;
  height: fit-content;
}

/* line 65, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/diplomas.sass */
.main__section__diplomas__popUp__modal__content__img {
  width: 100%;
  border-radius: 30px;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 3, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main {
  min-width: 100%;
  max-width: 100%;
}

/* line 7, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
}

/* line 15, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__container {
  max-width: 90vw;
  min-width: 90vw;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 40px;
}

/* line 24, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__container__wrapper {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__container__wrapper__question {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
  height: 200px;
  padding: 30px;
  border-radius: 50%;
  background: radial-gradient(90.77% 92.39% at 31.09% 10.65%, #FFE399 0%, #FFDA7A 24.76%, #FBD266 77.34%, #F5C850 88.15%, #EFC046 100%);
  box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.25);
  transition-duration: 1s;
  margin: 20px;
}

/* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__container__wrapper__question:hover {
  transition-duration: 1s;
  animation: bounce 1s;
}

/* line 48, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__container__wrapper__question__p {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #243E6C;
  font-weight: 500;
  text-align: center;
}

/* line 53, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__popUp {
  margin: 0px auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* line 59, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__popUp__modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60vw;
  height: 70vh;
  margin: 0px;
}

/* line 67, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__popUp__modal__content {
  width: 100% !important;
  margin: 0px !important;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 45px 45px 30px 30px;
  position: relative;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0) 100%) !important;
}

/* line 78, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__popUp__modal__content__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  font-weight: 600;
  max-width: 90vw;
  color: #243E6C;
  margin-bottom: 40px;
}

/* line 84, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.main__section__questions__popUp__modal__content__answer {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #243E6C;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  text-indent: 20px;
  max-height: 50vh;
  overflow-y: auto;
}

/* line 97, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/questions.sass */
.window__teacher {
  background-color: #fffcf8 !important;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 3, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 20;
  background-color: #7887c0;
}

/* line 13, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 40px 30px 40px;
}

/* line 20, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

/* line 26, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__links__link {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fffcf8;
  margin-right: 15px;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

/* line 36, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__links__link:hover {
  text-decoration: underline;
  color: #FFC93C;
  transition: all 0.5s ease-out;
}

/* line 40, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__payments {
  display: flex;
  justify-content: space-evenly;
}

/* line 45, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__payments__payment {
  width: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* line 49, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__payments__payment:nth-child(5) {
  margin-right: 0px;
}

/* line 51, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__left__rights {
  font-family: 'main';
  font-size: 14px;
  font-weight: 300;
  color: #fffcf8;
}

/* line 56, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right {
  display: flex;
  margin-right: 65px;
}

/* line 59, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__contacts {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

/* line 63, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__contacts__link {
  margin-bottom: 5px;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fffcf8;
  margin-right: 15px;
  font-weight: 500;
  transition: all 0.5s ease-out;
}

/* line 70, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__contacts__link:hover {
  text-decoration: underline;
  color: #FFC93C;
  transition: all 0.5s ease-out;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__contacts__link:nth-child(2) {
  margin-bottom: 15px;
}

/* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__contacts__info {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #fffcf8;
}

/* line 80, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__networks {
  display: flex;
  flex-direction: column;
}

/* line 83, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__networks__item {
  width: fit-content;
}

/* line 85, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/footer.sass */
.footer__content__right__networks__item__img {
  width: 50px;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 4, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* line 8, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form {
  margin: 0px auto;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  position: relative;
  transition: all 0.5s ease-out;
}

/* line 15, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  position: relative;
  max-height: 70vh;
  overflow: auto;
  border-radius: 30px;
  padding: 25px 20px 20px;
  background-color: #fffcf8 !important;
}

@media (min-width: 0px) and (max-width: 425px) {
  /* line 15, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
  .main__section__form__layout {
    max-height: 99vh;
  }
}

/* line 30, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__success {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #fffcf8;
  transition: all 0.5s ease-out;
}

/* line 41, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__success__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

/* line 45, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__success__p {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}

/* line 49, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

/* line 53, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__text {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
  width: 80%;
}

/* line 58, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__item {
  width: fit-content;
  height: fit-content;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* line 66, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__item__label {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

/* line 70, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__item__input {
  height: fit-content;
  padding: 10px;
  border-radius: 30px;
  background-color: #fffcf8;
  border: 1px solid #474747;
}

/* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__item__input:focus {
  border: none;
  border: 2px solid #FFC93C;
  outline: none;
}

/* line 80, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__item__error {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 12px;
  color: #550000;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

/* line 88, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #525c85;
  background-color: #fffcf8;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: #7887c0;
  color: #fffcf8;
}

/* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
.main__section__form__layout__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

/* line 94, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__button.disabled {
  background-color: #747474;
  cursor: not-allowed;
}

/* line 97, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/form.sass */
.main__section__form__layout__button.disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* line 8, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout {
  border-radius: 30px;
  width: 90vw !important;
  height: 90vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 15, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 50px;
  margin: 0px 22px 0px 0px;
  max-height: 80vh !important;
  overflow-y: auto;
  overflow-x: clip;
}

@media (max-width: 768px) {
  /* line 15, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content {
    padding: 0px 10px 0px 35px;
  }
}

/* line 28, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: #243E6C;
}

@media (max-width: 670px) {
  /* line 28, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__title {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  /* line 28, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__title {
    font-size: 18px;
  }
}

/* line 37, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__a {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #7887c0;
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
}

/* line 44, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__a:hover {
  text-decoration: underline;
  color: #c18f0d;
  transition: all 0.5s ease-out;
}

/* line 48, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__p {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #243E6C;
  text-decoration: none;
  text-align: start;
  padding: 0px 50px;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  /* line 48, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__p {
    font-size: 16px;
  }
}

/* line 60, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__ol__li {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #243E6C;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  /* line 60, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__ol__li {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  /* line 60, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__ol__li {
    font-size: 14px;
  }
}

/* line 73, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__ol__li__ul__li {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #243E6C;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  /* line 73, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__ol__li__ul__li {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  /* line 73, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__ol__li__ul__li {
    font-size: 12px;
  }
}

/* line 85, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__ol__li__ul__ways {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__ol__li__ul__ways__img {
  margin-right: 20px;
  width: 150px;
}

@media (max-width: 768px) {
  /* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__ol__li__ul__ways__img {
    width: 70px;
  }
}

@media (max-width: 425px) {
  /* line 90, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__ol__li__ul__ways__img {
    width: 40px;
  }
}

/* line 97, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__first {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #243E6C;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  /* line 97, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__first {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  /* line 97, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__first {
    font-size: 14px;
  }
}

/* line 109, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #243E6C;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

/* line 117, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__second {
  margin-left: 10px;
}

/* line 119, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
.main__docs__modal__layout__content__third {
  margin-left: 20px;
}

@media (max-width: 768px) {
  /* line 122, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  /* line 125, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/docs.sass */
  .main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
    font-size: 12px;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

/* line 16, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar {
  width: 10px;
}

/* line 19, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-track {
  background-color: #fffcf8;
}

/* line 22, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
::-webkit-scrollbar-thumb {
  background-color: #7887c0;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

@media (min-width: 0px) and (max-width: 1342px) {
  /* line 21, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .header__content__nav__a {
    margin-right: 20px;
  }
}

@media (min-width: 0px) and (max-width: 1342px) and (min-width: 0px) and (max-width: 1082px) {
  /* line 21, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .header__content__nav__a {
    margin-right: 15px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* line 32, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__left__links {
    flex-direction: column;
  }
}

@media (min-width: 769px) and (max-width: 1024px) and (min-width: 0px) and (max-width: 1000px) {
  /* line 38, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__right {
    flex-direction: column;
  }
  /* line 43, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__right__contacts__info {
    margin-bottom: 10px;
  }
  /* line 45, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__right__networks {
    flex-direction: row;
  }
  /* line 47, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__right__networks__item {
    margin-right: 20px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 58, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* line 63, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content {
    margin: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
  }
  /* line 70, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  /* line 76, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts__links {
    display: flex;
    flex-direction: column;
  }
  /* line 79, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts__links__link {
    margin-bottom: 5px;
    font-family: 'main';
    font-size: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #fffcf8;
    margin-right: 15px;
    font-weight: 500;
    transition: all 0.5s ease-out;
  }
  /* line 87, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts__links__link:hover {
    text-decoration: underline;
    color: #FFC93C;
    transition: all 0.5s ease-out;
  }
  /* line 91, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts__links__link:nth-child(2) {
    margin-bottom: 15px;
  }
  /* line 93, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts__networks {
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    flex-direction: column;
  }
  /* line 100, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__contacts__networks__item__img {
    width: 30px;
  }
  /* line 102, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__docs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  /* line 107, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__docs__link {
    font-family: 'main';
    font-size: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #fffcf8;
    margin-right: 15px;
    transition: all 0.5s ease-out;
  }
  /* line 113, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__docs__link:hover {
    text-decoration: underline;
    color: #FFC93C;
    transition: all 0.5s ease-out;
  }
  /* line 117, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__payments {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  /* line 122, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__payments__payment {
    width: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  /* line 126, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__payments__payment:nth-child(5) {
    margin-right: 0px;
  }
  /* line 128, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__info {
    font-family: 'main';
    font-size: 16px;
    font-weight: 400;
    font-size: 12px;
    color: #fffcf8;
    margin-bottom: 10px;
  }
  /* line 134, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .footer__content__rights {
    font-family: 'main';
    font-size: 10px;
    font-weight: 300;
    color: #fffcf8;
  }
}

@media (min-width: 0px) and (max-width: 1024px) {
  /* line 145, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greetPlane1 {
    width: 30vw;
  }
  /* line 147, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greetPlane2 {
    width: 30vw;
  }
  /* line 149, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet1 {
    right: 10%;
    width: 30vw;
  }
  /* line 152, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet2 {
    width: 35vw;
  }
  /* line 154, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__content {
    align-items: center;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 165, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greetPlane1 {
    width: 40vw;
  }
  /* line 167, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greetPlane2 {
    width: 40vw;
  }
  /* line 169, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet1 {
    right: 0%;
    width: 40vw;
  }
  /* line 172, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet2 {
    width: 45vw;
    left: 10%;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 620px) {
  /* line 172, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet2 {
    bottom: -50px;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 550px) {
  /* line 178, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greetPlane1 {
    width: 50vw;
  }
  /* line 180, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greetPlane2 {
    width: 50vw;
  }
  /* line 182, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet1 {
    display: none;
  }
  /* line 184, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__greet2 {
    width: 55vw;
    left: 30%;
    bottom: 20%;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 188, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__content {
    align-items: center;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 425px) {
  /* line 191, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__greeting__content__left__h1 {
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) {
  /* line 203, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__plane2 {
    opacity: 0.5;
    position: absolute;
    right: 0px;
    bottom: 43%;
    width: 40vw;
  }
  /* line 209, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content {
    width: 90vw;
    display: flex;
    justify-content: flex-end;
  }
  /* line 221, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info {
    margin-top: 100px;
    margin-left: 80px;
    width: 55vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1350px) {
  /* line 221, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info {
    width: 60vw;
    margin-left: 0px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) {
  /* line 228, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__title {
    font-family: 'display';
    line-height: 125%;
    font-size: 60px;
    font-weight: 700;
    padding-top: 15px;
    font-size: 56px;
    margin-bottom: 15px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 650px) {
  /* line 228, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__title {
    font-size: 48px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1230px) {
  /* line 228, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__title {
    font-size: 48px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 900px) {
  /* line 228, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__title {
    font-size: 40px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) {
  /* line 236, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__point {
    display: flex;
    align-items: center;
    margin: 0px 0px 30px 30px;
  }
  /* line 240, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__point__img {
    width: 5px;
    margin-right: 15px;
  }
  /* line 243, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__point__text {
    font-family: 'main';
    font-size: 18px;
    font-weight: 500;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1024px) {
  /* line 243, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info__point__text {
    font-size: 16px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) {
  /* line 248, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__note__noteImg {
    width: 25vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1350px) {
  /* line 248, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__note__noteImg {
    width: 30vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1024px) {
  /* line 254, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__info__title {
    font-size: 48px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) {
  /* line 258, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__note__noteImg {
    width: 25vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1350px) {
  /* line 258, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__note__noteImg {
    width: 30vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 266, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool {
    align-items: center;
  }
  /* line 268, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__plane1 {
    top: 20%;
    width: 60vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 600px) {
  /* line 268, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__plane1 {
    top: 17%;
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 500px) {
  /* line 268, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__plane1 {
    top: 15%;
    width: 80vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 400px) {
  /* line 268, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__plane1 {
    width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 280, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1 {
    margin: 0px;
    flex-direction: column-reverse;
    height: fit-content;
  }
  /* line 284, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__info {
    margin-top: 0px;
    width: 90vw;
  }
  /* line 291, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__note {
    align-self: flex-end;
    margin-bottom: 30px;
  }
  /* line 294, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__note__noteImg {
    width: 50vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 425px) {
  /* line 294, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block1__note__noteImg {
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 600px) {
  /* line 300, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__info__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 303, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__note {
    align-self: flex-start;
    margin-bottom: 30px;
  }
  /* line 306, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__note__noteImg {
    width: 50vw;
  }
}

@media (min-width: 0px) and (max-width: 768px) and (min-width: 0px) and (max-width: 425px) {
  /* line 306, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__preschool__content__block2__note__noteImg {
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1200px) {
  /* line 317, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__plane1, .main__section__programm__plane2 {
    width: 40vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1200px) {
  /* line 320, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__title {
    font-size: 40px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 965px) {
  /* line 320, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1024px) {
  /* line 327, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider {
    width: 60vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 768px) {
  /* line 327, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider {
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 685px) {
  /* line 327, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider {
    width: 80vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 600px) {
  /* line 327, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider {
    width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 425px) {
  /* line 327, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider {
    width: 99vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) {
  /* line 338, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 20% !important;
    z-index: 11;
  }
  /* line 344, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 20% !important;
    z-index: 11;
  }
  /* line 352, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed {
    width: 30vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 1024px) {
  /* line 352, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed {
    width: 40vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 768px) {
  /* line 352, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed {
    width: 50vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 685px) {
  /* line 352, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed {
    width: 60vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 425px) {
  /* line 352, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed {
    width: 75vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 768px) {
  /* line 362, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed__title {
    font-size: 30px;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 768px) {
  /* line 365, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed__img {
    width: 20vw;
  }
}

@media (min-width: 0px) and (max-width: 1440px) and (min-width: 0px) and (max-width: 768px) {
  /* line 368, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__programm__container__slider__slide__card__dashed__text {
    font-size: 16px;
  }
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 380, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__advantages__h1 {
    font-family: 'display';
    line-height: 125%;
    font-size: 60px;
    font-weight: 700;
    padding-top: 15px;
    font-size: 48px;
    color: #7887c0;
    margin-bottom: 50px;
  }
}

@media (min-width: 0px) and (max-width: 650px) and (min-width: 0px) and (max-width: 650px) {
  /* line 380, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__advantages__h1 {
    font-size: 48px;
  }
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 385, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__advantages__container {
    min-width: calc( 100vw - 30px);
    max-width: calc( 100vw - 30px);
  }
}

@media (min-width: 0px) and (max-width: 650px) and (min-width: 0px) and (max-width: 426px) {
  /* line 390, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__advantages__container__slider .swiper-button-prev {
    display: block !important;
    content: url("../img/arrow-left-rates.svg");
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  /* line 397, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__advantages__container__slider .swiper-button-next {
    display: block !important;
    content: url("../img/arrow-right-rates.svg");
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}

@media (min-width: 0px) and (max-width: 650px) {
  /* line 406, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__advantages__container__slider__slide__name {
    font-family: 'main';
    font-size: 24px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    min-width: 250px;
    max-width: 250px;
  }
}

@media (min-width: 0px) and (max-width: 950px) {
  /* line 419, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__h1 {
    margin-bottom: 0px;
  }
  /* line 421, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
  /* line 425, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box {
    min-width: 95vw;
    max-width: 95vw;
    margin-bottom: 30px;
  }
  /* line 429, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box__title {
    min-width: 70vw;
    max-width: 70vw;
    margin-bottom: 40px;
    font-size: 18px;
  }
  /* line 434, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box__choice {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
  }
  /* line 438, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box__choice__age {
    justify-content: space-evenly;
  }
  /* line 440, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box__choice__age:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  /* line 443, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box__choice__age__img {
    width: 30vw;
    margin-bottom: 0px;
  }
  /* line 446, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__box__choice__age__button {
    cursor: pointer;
    box-shadow: none;
    min-height: 46px;
    width: fit-content;
    padding: 10px 30px;
    color: #525c85;
    background-color: #fffcf8;
    border-radius: 30px;
    border: none;
    font-family: 'main';
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.5s ease-out;
    font-size: 13px;
    padding: 5px 10px;
    min-height: 35px;
    margin-top: 10px;
    text-transform: none;
    color: #fffcf8;
    background-color: #7887c0;
  }
  /* line 74, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/variables.sass */
  .main__section__forWhom__container__content__box__choice__age__button:hover {
    box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
    transition: all 0.5s ease-out;
  }
  /* line 455, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__forWhom__container__content__info {
    min-width: 80vw;
    max-width: 80vw;
    max-height: 50vh;
    margin-right: 0px;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 601px) and (max-width: 930px) {
  /* line 467, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container {
    padding-bottom: 150px;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 600px) {
  /* line 467, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container {
    padding-bottom: 100px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* line 473, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1 {
    width: 90vw;
    align-items: center;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 400px) {
  /* line 473, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1 {
    margin-bottom: 0px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* line 478, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  /* line 483, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__title {
    margin-bottom: 20px;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 400px) {
  /* line 483, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__title {
    padding: 25px 70px;
  }
  /* line 487, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__title__h1 {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* line 489, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__points {
    margin-left: 0px;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 550px) {
  /* line 491, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__points__point {
    display: flex;
    align-items: flex-start;
  }
  /* line 495, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__points__point__text {
    font-size: 18px;
  }
  /* line 497, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__left__points__point__img {
    width: 3rem;
    margin-right: 20px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* line 501, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group1__right {
    display: none;
  }
  /* line 503, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2 {
    width: 90vw;
    align-self: center;
  }
  /* line 506, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  /* line 512, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__title {
    padding: 40px 70px 15px;
    margin-bottom: 20px;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 400px) {
  /* line 512, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__title {
    padding: 40px 45px 15px;
  }
  /* line 517, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__title__h1 {
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* line 519, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__title__h1 {
    font-size: 36px;
  }
  /* line 521, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__points {
    margin-left: 0px;
    align-self: flex-start;
  }
}

@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 550px) {
  /* line 524, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__points__point {
    margin-bottom: 60px;
    display: flex;
    align-items: flex-start;
  }
  /* line 529, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__points__point__text {
    font-size: 18px;
  }
  /* line 531, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__right__points__point__img {
    width: 3rem;
    margin-right: 20px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* line 534, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__group2__left {
    display: none;
  }
  /* line 536, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__about__container__button {
    margin: 0px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  /* line 545, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__title {
    font-size: 18px;
  }
  /* line 547, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__button {
    margin-right: 0px;
  }
  /* line 551, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__content__points {
    margin-left: 0px;
    width: 90vw;
  }
  /* line 554, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__content__points__point {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  /* line 558, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__content__points__point__img {
    margin-right: 10px;
  }
  /* line 560, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__content__points__point__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 175%;
  }
  /* line 564, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__results__container__content__img {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 1100px) {
  /* line 573, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__title {
    font-size: 48px;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 1024px) {
  /* line 573, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 770px) {
  /* line 577, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__choice {
    width: 90vw !important;
    justify-content: center !important;
    align-items: center !important;
  }
  /* line 582, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__choice__age {
    font-size: 13px !important;
    margin-bottom: 10px !important;
  }
  /* line 585, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__choice__age__span {
    font-size: 10px !important;
  }
}

@media (min-width: 0px) and (max-width: 1100px) {
  /* line 588, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__choice__age:nth-child(1) {
    margin-right: 0px !important;
  }
  /* line 590, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__choice__age:nth-child(2) {
    margin-right: 0px !important;
  }
  /* line 593, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* line 600, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__title {
    margin-right: 0px;
  }
  /* line 602, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package .groupTitle {
    margin-left: 0px;
    margin-bottom: 10px;
  }
  /* line 605, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider {
    margin-bottom: 40px;
    width: 98vw !important;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 376px) {
  /* line 609, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card {
    width: 90vw !important;
    padding: 10px 3px !important;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 376px) {
  /* line 616, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card__title {
    font-size: 24px !important;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 424px) {
  /* line 616, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card__title {
    margin-top: 15px;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 376px) {
  /* line 622, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card__features {
    width: 90% !important;
  }
}

@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 424px) {
  /* line 625, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card__features__point {
    font-size: 12px;
  }
}

@media (min-width: 0px) and (max-width: 1100px) {
  /* line 628, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card__price {
    font-size: 36px !important;
  }
  /* line 630, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__rates__container__packages__age__package__slider__slide__card__price__note {
    font-size: 10px !important;
  }
}

@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 768px) {
  /* line 643, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider {
    min-width: 98vw;
    max-width: 98vw;
  }
}

@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 650px) {
  /* line 649, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  /* line 656, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}

@media (min-width: 0px) and (max-width: 1325px) {
  /* line 663, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide {
    margin-bottom: 20px;
  }
  /* line 665, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment {
    width: 45vw;
  }
}

@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 1024px) {
  /* line 665, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment {
    width: 60vw;
  }
}

@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 768px) {
  /* line 665, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment {
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 1325px) {
  /* line 680, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__info__name {
    font-family: 'main';
    color: #243E6C;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease-out;
  }
  /* line 688, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__info__date {
    font-family: 'main';
    margin-bottom: 10px;
    font-size: 13px;
    color: #616161b6;
    font-weight: 400;
    text-decoration: none;
  }
}

@media (min-width: 0px) and (max-width: 500px) {
  /* line 703, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container {
    max-height: fit-content;
    min-height: fit-content;
  }
  /* line 706, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__title {
    margin-top: 30px;
  }
  /* line 710, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider .swiper-pagination {
    width: 60vw !important;
    left: 19% !important;
  }
  /* line 713, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide {
    margin-bottom: 60px;
  }
  /* line 715, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment {
    height: 30vh;
    width: 80vw;
    flex-direction: column;
  }
  /* line 720, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  /* line 725, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__avatar {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  /* line 731, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__avatar__img {
    border-radius: 30px;
    margin: 5px 15px;
    width: 60px;
  }
  /* line 735, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__avatar__state {
    font-family: 'main';
    font-size: 12px;
    font-weight: 400;
    color: #575757;
  }
  /* line 740, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__links {
    display: flex;
    flex-direction: column;
  }
  /* line 743, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__links__name {
    font-family: 'main';
    color: #243E6C;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease-out;
  }
  /* line 750, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__links__name:hover {
    text-decoration: underline;
    color: #c48e19;
    transition: all 0.5s ease-out;
  }
  /* line 754, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__links__date {
    font-family: 'main';
    margin-bottom: 10px;
    font-size: 13px;
    color: #616161b6;
    font-weight: 400;
    text-decoration: none;
  }
  /* line 761, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__person__links__date:hover {
    text-decoration: underline;
    color: #c48e19;
    transition: all 0.5s ease-out;
  }
  /* line 766, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__info__text {
    max-height: 11rem;
    overflow-y: auto;
    font-size: 14px;
  }
  /* line 770, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__feedback__container__content__slider__slide__comment__likes {
    top: 0px;
    right: 0px;
  }
}

@media (min-width: 1240px) and (max-width: 1330px) {
  /* line 785, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__container__slider__slide__card__hover__container__info {
    font-size: 13px;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 500px) {
  /* line 793, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 850px) {
  /* line 796, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__container {
    min-width: 90vw;
    max-width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  /* line 801, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__container__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  /* line 807, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__container__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  /* line 815, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__container__slider__slide__card {
    width: 90%;
  }
}

@media (min-width: 0px) and (max-width: 850px) {
  /* line 826, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__right__text {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  /* line 837, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal {
    width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  /* line 840, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content {
    flex-direction: column;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  /* line 840, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content {
    padding: 25px 25px 15px 15px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  /* line 844, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
  /* line 850, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__img {
    width: 20%;
    margin-right: 30px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 950px) {
  /* line 850, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__img {
    width: 40%;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  /* line 850, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__img {
    width: 30%;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  /* line 857, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__info {
    width: 50%;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  /* line 860, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__info__name {
    font-size: 4vw;
  }
  /* line 862, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__info__post {
    font-size: 3vw;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  /* line 864, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__info__name {
    text-align: start;
  }
  /* line 866, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__left__info__post {
    text-align: start;
  }
  /* line 868, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__right {
    width: 100%;
    max-height: 20rem;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  /* line 868, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__teachers__popUp__modal__content__right {
    max-height: 30rem;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 500px) {
  /* line 880, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__diplomas__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 850px) {
  /* line 883, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__diplomas__container {
    min-width: 90vw;
    max-width: 90vw;
  }
  /* line 887, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__diplomas__container__slider .swiper-pagination {
    width: 70% !important;
    bottom: 0px !important;
    left: auto !important;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  /* line 892, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__diplomas__container__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  /* line 898, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__diplomas__container__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}

@media (min-width: 0px) and (max-width: 850px) {
  /* line 904, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__diplomas__container__slider__slide {
    margin-bottom: 100px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  /* line 921, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal {
    width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  /* line 924, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content {
    flex-direction: column;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  /* line 924, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content {
    padding: 25px 25px 15px 15px;
  }
  /* line 928, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content__title {
    margin-bottom: 20px;
  }
  /* line 930, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content__answer {
    max-height: 30rem;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 500px) {
  /* line 924, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content {
    padding: 30px 25px 15px 15px;
  }
  /* line 934, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  /* line 937, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/mobile.sass */
  .main__section__questions__popUp__modal__content__answer {
    max-height: 30rem;
    font-size: 16px;
  }
}

/* line 20, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/style.sass */
* {
  margin: 0;
  padding: 0;
}

/* line 23, ../../Рабочий стол/shineschool/ShineSchoolKids/src/sass/style.sass */
*::selection {
  color: #ffe5a2;
  background-color: #364169;
}

@media screen and (max-width: 1024) {
  transform-origin: top left;
  transform: scale(0.5);
  width: 200%;
}

/*# sourceMappingURL=style.css.map */